.main-content {
  height: 100%;

  .container:first-of-type {
    height: 100%;
  }

  .content-block, .action-panel, .action-panel-container {
    padding-left: initial;
    padding-right: initial;
  }

  .content-block {
    overflow: initial;
    height: initial;
  }

  .drawer {
    &__close {
      display: none;

      &--full {
        display: inline-flex;
      }
    }

    &__open {
      &--full {
        display: none;
      }
    }
  }

  .btn-return {
    display: inline-flex;
  }

  .btn-cancel {
    display: none;
  }

  .btn-edit {
    display: none;

    &--full {
      display: inline-flex;
    }
  }
}

.container {
  max-width: 1440px !important;
}
html {
    scroll-behavior: smooth;
}

html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    display: flex;
    flex-direction: column;
}

header {
    flex: 0 0 auto;
}

.main {
    flex: 1 1 auto;
    overflow-x: hidden;
    padding-bottom: 100px;
    position: relative;

    &--login {
        padding-bottom: initial;
        @media (max-height: 700px) {
            padding: 50px 0;
        }

        .main-content {
            @media (max-height: 720px) {
                 height: initial;
            }
        }
    }

    &--password-reset {
        padding-bottom: initial;
        @media (max-height: 575px) {
            padding: 50px 0;
        }
    }
}

footer {
    flex: 0 0;
}

.form-select, .form-control::placeholder {
    color: $grey-500;
}

.form-control {
    color: inherit;
}

a {
    color: $black;

    &:hover {
        color: $primary-400;
    }
}

.btn {
  padding: 8px 12px;
  font-size: inherit;
  font-weight: $fw-500;
  border-radius: 8px;
  border: 1px solid $grey-300;
  box-shadow: 0 1px 2px 0 rgb(16 24 40 / 0.05);

  .material-symbols-outlined {
    font-size: 22px;
  }

  &-square {
    padding: 7px;
    border-radius: 6px;
  }

  &-primary {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    column-gap: 6px;
    border-color: $primary-400;
    background-color: $white;
    color: $primary-400;
    padding: 8px 12px;

    &:hover, &:active, &:focus-visible {
      background-color: $primary-50 !important;
      border-color: $primary-400 !important;
      color: $primary-400 !important;
    }

    &:focus-visible {
      box-shadow: 0 0 0 0.25rem $primary-50;
    }
  }

  &-remove, &-edit {
    border: initial;
    border-radius: 50%;
    box-shadow: initial;
    color: $grey-500;
    display: inline-flex;
    padding: 6px;

    .material-symbols-outlined {
        font-size: 22px;
    }
  }

  &-remove {
     &:hover, &:active, &:focus-visible {
       background-color: $error-50;
       color: $error-400;
     }
    &[disabled] {
      opacity: 0.25;
    }
  }

  &-edit {
    &:hover, &:active, &:focus-visible {
      background-color: $success-50;
      color: $success-400;
    }

    &--full {
      display: none;
    }
  }


  &-confirm {
    box-shadow: initial;
    display: inline-flex;
    padding: .5rem;
    border-radius: 50%;
    border: 1px solid transparent;
    transition: border .2s linear;

    &--yes {
      color: $success-400;

      &:hover, &:active, &:focus-visible {
        border-color: $success-400;
        color: $success-400;
      }
    }

    &--no {
      color: $error-400;

      &:hover, &:active, &:focus-visible {
        border-color: $error-400;
        color: $error-400;
      }
    }
  }

  &-cancel {
    display: inline-flex;
  }

  &-add {
    align-items: center;
    background-color: initial;
    border-radius: 50%;
    border: initial;
    display: inline-flex;
    justify-content: center;
    margin: 4px;
    padding: 4px;
    transition: all 0.2s linear;

    &:hover, &:active, &:focus-visible {
      background-color: $primary-50;
    }

    .material-symbols-outlined {
      font-size: 22px;
      color: $primary-400;
    }
  }
}
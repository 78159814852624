$black: #000;
$grey-900: #101928;
$grey-800: #1D2739;
$grey-700: #344054;
$grey-600: #475367;
$grey-500: #667185;
$grey-400: #98A2B3;
$grey-300: #D0D5DD;
$grey-200: #E4E7EC;
$grey-100: #F0F2F5;
$grey-50: #F9FAFB;
$white: #FFF;

$primary-900: #711E00;
$primary-800: #8F2802;
$primary-700: #AD3307;
$primary-600: #CC400C;
$primary-500: #EB5017;
$primary-400: #F56630;
$primary-200: #FA9874;
$primary-100: #FCB59A;
$primary-75: #FCD2C2;
$primary-50: #FFECE5;
$primary-25: #FEF5F2;

$error-700: #9E0A05;
$error-400: #D42620;
$error-75: #F2BCBA;
$error-50: #FBEAE9;

$secondary-50: #E3EFFC;

$success-700: #036B26;
$success-400: #0F973D;
$success-50: #E7F6EC;

$warning-300: #F5B546;
$warning-50: #FEF6E7;

.grey-900 {
  color: $grey-900;
}

.grey-600 {
  color: $grey-600;
}

.grey-500 {
  color: $grey-500;
}

.grey-400 {
  color: $grey-400;
}

.content-block {
  height: 100%;
  //max-width: 50vw;
  //margin: 0 auto;
  overflow: auto;
  padding-bottom: 180px;

  @media (min-width: 576px) {
    padding: 0 48px 180px;
  }
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/inter/Inter-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/inter/Inter-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/inter/Inter-SemiBold.woff2') format('woff2');
}


body {
  font-family: 'Inter', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 145%;
  color: $grey-700;
}

$fs-12: 0.75rem;
$fs-16: 1rem;
$fs-20: 1.25rem;
$fs-24: 1.5rem;

$fw-500: 500;
$fw-600: 600;

.fs-20 {
  font-size: $fs-20;
}

.fs-24 {
  font-size: $fs-24;
}

.fw-500 {
  font-weight: $fw-500;
}

.fw-600 {
  font-weight: $fw-600;
}
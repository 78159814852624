.account-list {
  position: relative;
  overflow: hidden;
  padding-bottom: 100px;
  min-height: 100%;
}

.relationship-stars {
  span {
    font-size: 18px;
    color: $grey-300;
  }

  &--one {
    span:nth-child(1) {
      color: $grey-600;
    }
  }

  &--two {
    span:nth-child(-n+2) {
      color: $grey-600;
    }
  }


  &--three {
    span:nth-child(-n+3) {
      color: $grey-600;
    }
  }

  &--four {
    span:nth-child(-n+4) {
      color: $grey-600;
    }
  }

  &--five {
    span:nth-child(-n+5) {
      color: $grey-600;
    }
  }
}

.action-panel {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 16px 16px 48px;
    position: relative;

    @media (max-width: 575px) {
      padding-left: initial;
      padding-right: initial;
    }

    @media (min-width: 576px) {
      flex-direction: row;
      align-items: center;
    }
  }

  @media (min-width: 576px) {
    padding: 16px 24px;
  }

  .btn {
    border: initial;
    background-color: $grey-100;
    padding: 6px;

    &.rounded-circle {

      &:hover, &:active, &:focus-visible {
        background-color: $grey-200;
      }
    }

    &-edit {
      &:hover, &:active, &:focus-visible {
        background-color: $success-50;
        color: $success-400;
      }
    }

    &-remove {
      &:hover, &:active, &:focus-visible {
        background-color: $error-50;
        color: $error-400;
      }
    }
  }

  .delete__confirmation--active {
    .btn-confirm--yes {
      background-color: initial;
      border: 1px solid transparent;

      &:hover {
        border-color: $success-400;
      }
    }

    .btn-confirm--no {
      background-color: initial;
      border: 1px solid transparent;

      &:hover {
        border-color: $error-400;
      }

    }
  }
}
header {
    z-index: 1;

    .navbar {
        background-color: $grey-50;
        &-brand {
            display: inline-flex;
            margin: initial;
        }
    }

    .logo {
        font-size: 32px;

        &--left {
            color: $grey-400;
        }

        &--right {
            margin-left: -18.5px;
            color: $primary-400;
        }
    }
}

.body--login {
    .navbar-nav {
        .nav-item {
            display: none;

            &--lang {
                display: list-item;
            }
        }
    }
}
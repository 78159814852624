.drawer {
  &-wrapper {
    background-color: white;
    border-left: 1px solid $grey-200;
    height: 100%;
    padding: inherit;
    position: fixed;
    right: -100%;
    transition: right 0.2s ease-in-out;
    top: 58px;
    width: 100%;
    //padding: 56px 48px 0;
    //box-shadow: rgba(0, 0, 0, 15%) -1px 0px 4px;

    @media (max-width: 575px) {
      padding: 0 24px 48px;
    }

    @media (min-width: 576px) {
      max-width: 75%;
      right: -75%;
    }

    @media (min-width: 1200px) {
      max-width: 50%;
      right: -50%;
    }

    &--open {
      right: 0;

      .drawer__close {
        display: inline-flex;
      }
    }

    .container {
      //overflow: auto;
      height: 100%;
      padding: initial;
    }

    .btn-return {
      display: none;
    }

  }

  &__open {
    cursor: pointer;

    &--full {
      display: inline-flex;

      .material-symbols-outlined {
        font-size: 20px;
        padding: 1px;
      }
    }
  }

  &__toggle {
    gap: 8px;
    display: flex;
    margin-right: 8px;
  }

  &__close {
    display: inline-flex;

    &:hover {
      background-color: #f2f2f2;
    }

    &--full {
      display: none;
    }
  }
}
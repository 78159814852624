.table {
  overflow: hidden;
  border-color: $grey-200;

  &__container {
    //border-radius: 10px;
    //border: 1px solid $grey-200;
    box-shadow: 0 4px 4px -2px rgb(0 0 0 / 0.04);
    overflow: hidden;
  }

  th {
    background-color: $grey-50;
    font-size: $fs-12;
    font-weight: $fw-500;
    color: $grey-700;
    padding: 12px 24px;
    position: relative;
  }

  td {
    color: inherit;
    padding: 12px 24px;

    &:first-child {
      color: $grey-900;
    }

    &.relationship-stars {
      vertical-align: middle;

      .material-symbols-outlined {
        vertical-align: middle;
      }
    }
  }

  &-row {
    position: relative;

    &:hover {
      td {
        background-color: $grey-50;
      }
    }
  }

  &--details {
    width: auto;
    table-layout: auto;

    &-container {
      overflow: auto;
      margin-bottom: 32px;
    }

    th, td {
      white-space: nowrap;
      height: 40px;

      &:first-child {
        padding-right: 100px;

        @media (max-width: 575px) {
          padding-right: 50px;
        }
      }
    }
  }
}

.resizer {
  position: absolute;
  top: 0;
  right: 0;
  width: 4px;
  height: 100%;
  cursor: col-resize;
  background-color: transparent;
}

.resizer:hover {
  background-color: $grey-200;
}

.table-fixed {
  table-layout: fixed;
  width: 100%;
}

.table-fixed th,
.table-fixed td {
  white-space: nowrap; /* Prevents wrapping, optional */
  overflow: hidden; /* Hides overflowing text */
  text-overflow: ellipsis; /* Adds ellipsis for truncated text */
}

.table-fixed {
  th {
    border-right: 1px solid $grey-200;

    &:last-child {
      border-right: none;
      width: 86px;
    }
  }
}

/* Account table column width */
#account-list {
  th {
    &:nth-child(1) {
      width: 174px;
    }

    &:nth-child(2) {
      width: 174px;
    }

    &:nth-child(3) {
      width: 174px;
    }

    &:nth-child(4) {
      width: 174px;
    }

    &:nth-child(5) {
      width: 161px;
    }

    &:nth-child(6) {
      width: 118px;
    }

    &:nth-child(7) {
      width: 174px;
    }
  }
}

/* Contract table column width */
#contract-list {
  th {
    &:nth-child(1) {
      width: 300px;
    }

    &:nth-child(2) {
      width: 200px;
    }

    &:nth-child(3) {
      width: 150px;
    }

    &:nth-child(4) {
      width: 150px;
    }

    &:nth-child(5) {
      width: 150px;
    }

    &:nth-child(6) {
      width: 150px;
    }

    &:nth-child(7) {
      width: 150px;
    }

    &:nth-child(8) {
      width: 150px;
    }

    &:nth-child(9) {
      width: 190px;
    }

    &:nth-child(10) {
      width: 190px;
    }

    &:nth-child(11) {
      width: 150px;
    }

    &:nth-child(12) {
      width: 150px;
    }

    &:nth-child(13) {
      width: 180px;
    }

    &:nth-child(14) {
      width: 180px;
    }

    &:nth-child(15) {
      width: 150px;
    }

    &:nth-child(16) {
      width: 150px;
    }

    &:nth-child(17) {
      width: 150px;
    }
  }
}

/* Product table column width */
#product-list {
  th {
    &:nth-child(1) {
      width: 400px;
    }

    &:nth-child(2) {
      width: 506px;
    }

    &:nth-child(3) {
      width: 200px;
    }

    &:nth-child(4) {
      width: 150px;
    }

    @media (max-width: 1365px) {
      &:nth-child(1) {
        width: 174px;
      }

      &:nth-child(2) {
        width: 174px;
      }

      &:nth-child(3) {
        width: 174px;
      }

      &:nth-child(4) {
        width: 150px;
      }
    }
  }
}

/* Attachment table column width */
#attachment-list {
  th {
    &:nth-child(1) {
      width: calc(50% - 43px);
    }

    &:nth-child(2) {
      width: calc(50% - 43px);
    }


    @media (max-width: 575px) {
      &:nth-child(1) {
        width: 300px;
      }

      &:nth-child(2) {
        width: 300px;
      }
    }
  }
}
.delete {
  &__item {
    &--confirm {
      display: none;
    }

    &--close {
      display: none;
    }

    &-cell {
      width: 86px;
    }
  }

  &__confirmation {
    display: flex;
    column-gap: 10px;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
    right: -30%;
    height: 100%;
    background-color: #FBEAE9;
    padding: 0 20px;
    border-left: 5px solid #F2BCBA;
    opacity: 0;
    transition: all 0.2s ease-in-out;

    &--active {
      right: 0;
      opacity: 1;

      .delete__item {
        &--confirm {
          display: inline-flex;
        }

        &--close {
          display: inline-flex;
        }
      }
    }
  }
}
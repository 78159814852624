form {
  label {
    color: $grey-500;
    display: block;
    font-weight: $fw-500;
    margin-bottom: 4px;
    padding: 2px 0;
  }

  input, select, textarea, .form-select, .form-control {
    border-radius: 6px;
    border: 1px solid $grey-300;
    box-shadow: 0px 3px 2px -2px rgba(0, 0, 0, 0.06), 0px 5px 3px -2px rgba(0, 0, 0, 0.02);
    color: $grey-900;
    font-size: 14px;
    line-height: 145%;
    max-width: 375px;
    padding: 8px 12px;
    width: 100%;

    &::placeholder {
      color: $grey-400;
      font-size: 14px;
      line-height: 145%;
    }

    &:focus, &:focus-visible {
      outline: initial;
      box-shadow: initial;
      border-color: $primary-400;
    }
  }

  select {
    --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
    display: block;
    width: 100%;
    padding: .375rem 2.25rem .375rem .75rem;
    color: var(--bs-body-color);
    appearance: none;
    background-color: var(--bs-body-bg);
    background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
  }

  @media (prefers-reduced-motion: reduce) {
    select {
      transition: none
    }
  }

  .form-select:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25)
  }

  .form-control, .form-select {

    &:focus, &:focus-visible {
      outline: initial;
      box-shadow: initial;
      border-color: $primary-400;
    }
  }

  input[type="checkbox"] {
    width: initial;
    max-width: initial;
  }

  .btn-square {
    margin-bottom: 2px;
  }

}

.form {
  &__item-add {
    display: none;
  }

  &-group {
    padding: 12px 0;
    position: relative;

    input[type="checkbox"] {
      height: 18px;
      width: 18px;
    }

    &.checkbox label {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .form-collapse {
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
      transition: all .2s linear;
      background: white;
      z-index: 2;
      width: 100%;
      max-width: 0;
      visibility: hidden;

      &.open {
        max-width: 422px;
        visibility: visible;
        background-color: #F9FAFB;
      }

      label {
        white-space: nowrap;
      }

      input {
        max-width: initial;
      }
    }

    &.open {
      .form__item {

        &-current {
          display: none;
        }

        &-add {
          display: block;

          label {
            background: $primary-50;
            border-radius: 15px;
            display: inline-block;
            padding: 2px 8px;
          }
        }
      }

      .btn-add {
        transform: rotate(45deg);
      }
    }

    .form-card--add {
      padding: 20px 16px;
      border: 1px solid #dee2e6;
      border-radius: 6px;
    }
  }
}

.login-form {
  width: 400px;
  max-width: 100%;

  .form-input {
    &__container {
      position: relative;

      input {
        line-height: 24px;
        padding: 8px 12px;
        max-width: 100%;
      }
    }


    &__icon {
      align-items: center;
      background: none;
      border: none;
      color: $grey-400;
      display: inline-flex;
      font-size: 22px;
      flex-direction: column;
      justify-content: center;
      padding: 0;
      position: absolute;
      right: 4%;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .forget-password {
    a {
      color: $primary-500;

      &:hover {
        color: $primary-600;
      }
    }
  }

   button[type="submit"] {
     color: #fff;
     background-color: $primary-400;
     width: 100%;
     padding: 16px 0;
     margin-top: 20px;
     border: initial;

     &:hover {
       background-color: $primary-500;
     }
  }
}

.error-message {
  display: none;
  color: $error-400;
  margin-top: 8px;
}

.error-text {
  color: $error-400;
  margin-top: 8px;

  ul {
    padding-left: initial;
    margin-bottom: initial;
    list-style: none;
  }
}

.border-error {
  border-color: $error-400;

  &.form-select {
    border-color: $error-400;
  }

  &.form-control {
    border-color: $error-400;
  }
}

.login-social {
  max-width: 400px;
  width: 100%;
  margin: 24px auto;
  padding-top: 14px;
  position: relative;

  &__hr {
    border-color: $grey-100;
    opacity: 1;
    margin-top: initial;
  }

  &__text {
    position: absolute;
    top: 4px;
    left: 50%;
    background: white;
    padding: 0 8px;
    transform: translateX(-50%);
  }

  &__btn {
    border: 1.5px solid $grey-300;
    padding: 16px;
    text-decoration: none;
    border-radius: 6px;
    width: 100%;
    margin-top: 32px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 16px;

    &-img {
      width: 20px;
      height: 20px;
    }

    &-text {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 145%;
    }
  }
}

#search-form {
  position: relative;
  width: 100%;

  @media (min-width: 992px) {
    max-width: 270px;
  }

  #search-input {
    color: $grey-500;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%;
    max-width: initial;
    padding-bottom: 8px;
    padding-left: 42px;
    padding-top: 8px;

    &::placeholder {
        color: $grey-500;
    }
  }

  .material-symbols-outlined {
    position: absolute;
    top: 50%;
    left: 12px;
    color: $grey-400;
    transform: translateY(-50%);
  }
}

#status-filter {
  padding-bottom: 8px;
  color: $grey-500;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  padding-top: 8px;
  max-width: initial;

  @media (min-width: 992px) {
    width: 50%;
  }
}
.nav-link.active {
  color: $primary-400 !important;
  font-weight: bold;
}

.nav-link {
  padding-left: 5px;
}

.navbar-toggler {
  border: initial;

  &:focus {
    box-shadow: initial;
  }
}
